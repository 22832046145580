import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-129e1c00"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "ps-3" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-sm-6" }
const _hoisted_5 = { class: "col-sm-6" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-sm-6" }
const _hoisted_8 = { class: "col-sm-6" }
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = { class: "d-block fs_18 mt-3 pt-1" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-sm-6" }
const _hoisted_13 = { class: "col-sm-6" }
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { class: "col-sm-6" }
const _hoisted_16 = { class: "col-sm-6" }
const _hoisted_17 = { class: "ps-3" }
const _hoisted_18 = { class: "row" }
const _hoisted_19 = { class: "col-sm-6" }
const _hoisted_20 = { class: "form-group" }
const _hoisted_21 = { class: "d-block fs_18 mt-3 pt-1" }
const _hoisted_22 = { class: "col-sm-6" }
const _hoisted_23 = { class: "form-group" }
const _hoisted_24 = { class: "d-block fs_18 mt-3 pt-1" }
const _hoisted_25 = { class: "d-flex justify-content-end mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_form_field = _resolveComponent("form-field")!

  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_localized_text, {
          localizedKey: "Sheep farming",
          text: "Sheep farming",
          class: "dividerTitle mt-0"
        }),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_form_field, {
                type: "number",
                labelKey: "Stock (unit)",
                labelValue: "Stock (unit)",
                placeholderKey: "Stock (unit)",
                placeholderValue: "Stock (unit)",
                modelValue: _ctx.model.woolInfo.stock,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.woolInfo.stock) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_form_field, {
                type: "number",
                labelKey: "Stock rate (unit/ha)",
                labelValue: "Stock rate (unit/ha)",
                placeholderKey: "Stock rate (unit/ha)",
                placeholderValue: "Stock rate (unit/ha)",
                modelValue: _ctx.model.woolInfo.stockRate,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.woolInfo.stockRate) = $event))
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_form_field, {
                type: "number",
                labelKey: "Wool production (kg)",
                labelValue: "Wool production (kg)",
                placeholderKey: "Wool production (kg)",
                placeholderValue: "Wool production (kg)",
                modelValue: _ctx.model.woolInfo.woolProduction,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.woolInfo.woolProduction) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_localized_text, {
                  localizedKey: "Yield (kg/unit)",
                  text: "Yield (kg/unit)",
                  type: "label",
                  class: "input_title"
                }),
                _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$filters.approximation(_ctx.getYieldKgUnit)), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_form_field, {
                type: "number",
                labelKey: "Sowing (kg)",
                labelValue: "Sowing (kg)",
                placeholderKey: "Sowing (kg)",
                placeholderValue: "Sowing (kg)",
                modelValue: _ctx.model.woolInfo.sowing,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.woolInfo.sowing) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_form_field, {
                type: "number",
                labelKey: "Feed-Sillage (kg)",
                labelValue: "Feed-Sillage (kg)",
                placeholderKey: "Feed-Sillage (kg)",
                placeholderValue: "Feed-Sillage (kg)",
                modelValue: _ctx.model.woolInfo.feedSilage,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.woolInfo.feedSilage) = $event))
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createVNode(_component_form_field, {
                type: "number",
                labelKey: "Feed-Maize grain (kg)",
                labelValue: "Feed-Maize grain (kg)",
                placeholderKey: "Feed-Maize grain (kg)",
                placeholderValue: "Feed-Maize grain (kg)",
                modelValue: _ctx.model.woolInfo.feedMaizeGrain,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.woolInfo.feedMaizeGrain) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_16, [
              _createVNode(_component_form_field, {
                type: "number",
                labelKey: "Feed-Hay (kg)",
                labelValue: "Feed-Hay (kg)",
                placeholderKey: "Feed-Hay (kg)",
                placeholderValue: "Feed-Hay (kg)",
                modelValue: _ctx.model.woolInfo.feedHay,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.woolInfo.feedHay) = $event))
              }, null, 8, ["modelValue"])
            ])
          ])
        ]),
        _createVNode(_component_localized_text, {
          localizedKey: "Wool",
          text: "Wool",
          class: "dividerTitle"
        }),
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("div", _hoisted_20, [
                _createVNode(_component_localized_text, {
                  localizedKey: "F-Wool (kg CO2e)",
                  text: "F-Wool (kg CO2e)",
                  type: "label",
                  class: "input_title"
                }),
                _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.$filters.approximation(_ctx.getFWoolKgCO2e)), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("div", _hoisted_23, [
                _createVNode(_component_localized_text, {
                  localizedKey: "F-Wool (kg CO2e / kg)",
                  text: "F-Wool (kg CO2e / kg)",
                  type: "label",
                  class: "input_title"
                }),
                _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.$filters.approximation(_ctx.getFWoolKgCO2eKg)), 1)
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_25, [
          _createElementVNode("button", {
            class: "btn btn-secondary me-3",
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$router.back()))
          }, [
            _createVNode(_component_localized_text, {
              localizedKey: "Previous",
              text: "Previous"
            })
          ]),
          _createElementVNode("button", {
            class: "btn btn-tertiary",
            onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.updateWoolInfo()))
          }, [
            _createVNode(_component_localized_text, {
              localizedKey: "Continue",
              text: "Continue"
            })
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}