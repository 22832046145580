
import { Options, Vue } from 'vue-class-component';
import { FabricEstimationClient } from '@/services/Services';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class Wool extends Vue {

    estimationIdentifier: string = "";
    model: OM.FabricWoolVM = new OM.FabricWoolVM();

    loaded: boolean = false;

    created() {
        this.estimationIdentifier = this.$route.params.estimationIdentifier.toString();

        FabricEstimationClient.getWoolInfo(this.estimationIdentifier)
        .then(x => {
            this.model.estimationIdentifier = this.estimationIdentifier;
            this.model.woolInfo = x;

            this.$nextTick(() => {
                this.loaded = true;
            })
        })
    }

    get getYieldKgUnit() {
        if(!this.model.woolInfo.woolProduction || !this.model.woolInfo.stock)
            return 0;

        return this.model.woolInfo.woolProduction / this.model.woolInfo.stock;
    }
    get getFWoolKgCO2e() {
        if(!this.getYieldKgUnit)
            return 0;

        return (this.model.woolInfo.sowing * this.getYieldKgUnit) + (this.model.woolInfo.feedSilage * this.getYieldKgUnit) + (this.model.woolInfo.feedMaizeGrain * this.getYieldKgUnit) + (this.model.woolInfo.feedHay * this.getYieldKgUnit);
    }
    get getFWoolKgCO2eKg() {
        if(!this.getFWoolKgCO2e || !this.model.woolInfo.woolProduction)
            return 0;

        return this.getFWoolKgCO2e / this.model.woolInfo.woolProduction;
    }

    updateWoolInfo() {
        FabricEstimationClient.updateWoolInfo(this.model)
        .then(x => {
            this.$router.push("/fabric-calculator/rawmaterials/" + this.estimationIdentifier);
        })
    }
}
